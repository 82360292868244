import React ,{Fragment} from 'react'
import UserLogin from '../Components/UserComponents/UserLogin/UserLogin'


function UserLoginpage() {
  return (
    <Fragment>
        <UserLogin/>
    </Fragment>
  )
}

export default UserLoginpage