import React, { Fragment } from 'react'
import UserSignup from '../Components/UserComponents/UserSignup/UserSignup'


function UserSignupPage() {
  return (
    <Fragment>
        <UserSignup/>
    </Fragment>
  )
}

export default UserSignupPage