import React, { Fragment } from 'react'
import SingleRoom from '../Components/ViewPage/SingleRoom'

function SinglePage() {
    return (
        <Fragment>
            <SingleRoom />
        </Fragment>
    )
}

export default SinglePage