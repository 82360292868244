import React, { Fragment } from 'react'
import Cancel from '../../Components/Stripe/Cancel'

function Cancelpage() {
    return (
        <Fragment>
            <Cancel />
        </Fragment>
    )
}

export default Cancelpage