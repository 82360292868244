import React, { Fragment } from 'react'
import Success from '../../Components/Stripe/Success'

function Sucesspage() {
    return (
        <Fragment>
            <Success />
        </Fragment>
    )
}

export default Sucesspage