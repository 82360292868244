import React,{Fragment} from 'react'
import UserProfile from '../Components/UserComponents/Userprofile/UserProfile'

function UserProfilepage() {
  return (
    <Fragment>
        <UserProfile/>
    </Fragment>
  )
}

export default UserProfilepage