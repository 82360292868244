import React, { Fragment } from 'react'
import Admin from '../../Components/AdminComponent/Admin-Index'

function Adminpage() {
  return (
    <Fragment>
    <Admin/>
  </Fragment>
  )
}

export default Adminpage