import React, { Fragment } from 'react'
import HostBody from '../Components/HostComponents/HostBody/HostBody'
import HostHeader from '../Components/HostComponents/HostHeader/HostHeader'
// import Footer from '../Components/HomeComponents/Footer/Footer'

function HostdashboardPage() {
    return (
        <Fragment>
            <HostHeader/>
            <HostBody/>
            
        </Fragment>
    )
}

export default HostdashboardPage