import React, { Fragment } from 'react'
import Message from '../../Components/ChatComponent/Message'

function Chatpage() {
  return (
    <Fragment>

        <Message/>
    
  </Fragment>
  )
}

export default Chatpage