import React,{Fragment} from 'react'
import BookingRequest from '../Components/Reservation/BookingRequest'

function BookingRequestPage() {
  return (
    <Fragment>
    <BookingRequest/> 
  </Fragment>
  )
}

export default BookingRequestPage

