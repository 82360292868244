import React,{Fragment} from 'react'
import CreateuserProfile from '../Components/UserComponents/Userprofile/CreateuserProfile'

function Createuserprofilepage() {
  return (
    <Fragment>
      <CreateuserProfile/>
    </Fragment>
  )
}

export default Createuserprofilepage