import React, { Fragment } from 'react'
import Body from '../Components/HomeComponents/Body/Body'


function Home() {
  return (
    <Fragment>
      <Body/>
    </Fragment>
  )
}

export default Home