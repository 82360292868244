import React, { Fragment } from 'react'
import Login from '../../Components/AdminComponent/Login'

function Loginpage() {
  return (
    <Fragment>
    <Login/>
  </Fragment>
  )
}

export default Loginpage